import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AppComponent } from './app/app.component';
import { AppInitializationService } from './app/shared/services/app-initialization.service';
import { environment } from './environments/environment';
import { AppRoutingModule } from './app/app-routing.module';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    AppInitializationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppInitializationService]
    },
    importProvidersFrom(
      RouterModule.forRoot([], {}),
      FeatherModule.pick(allIcons),
      ScrollToModule.forRoot(),
      FlatpickrModule.forRoot(),
      BrowserAnimationsModule,
      AppRoutingModule
    )
  ]
});

export function appInit(appInitService: AppInitializationService) {
  return (): any => appInitService.load();
}
