import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComingsoon2Component } from './core/components/page-comingsoon2/page-comingsoon2.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'coming-soon', pathMatch: 'full'
  },
  {
    path: 'coming-soon',
    component: PageComingsoon2Component
  }
  // {
  //   path: '',
  //   component: MasterPageComponent,
  //   children: [
  //     { path: '', component: PageComingsoon2Component }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "top",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
