/**
 * GTRAK
 */
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Ce service s'occupe de récupérer les données de configuration personnalisées.
 * Par la suite, ces données sont disponibles en interrogeant le service.
 * L'activation de ce service, au load de l'application, est assuré par le token APP_INITIALIZER, injecté dans AppModule
 *
 * De plus, ce service est chargé d'obtenir le plan d'abonnement du client et ainsi fournir cette information au service
 * de configuration des modules et routes inclus dans l'abonnement.
 */
@Injectable({ providedIn: 'root' })
export class AppInitializationService {

  constructor() {
  }

  async load(): Promise<void> {
    return Promise.resolve();
  }
}
